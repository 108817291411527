<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 text-left>
        <span class="mainHeader">PURCHASE</span>
      </v-flex>
      <!-- <v-flex xs1 text-right>
        <v-btn
          block
          dark
          color="#3F053C"
          v-if="GstStat == 'GST'"
          small
          @click="changeGST2()"
        >
        </v-btn>
        <v-btn
          block
          dark
          color="#3F053C"
          v-if="GstStat == 'NOGST'"
          small
          @click="changeGST()"
        >
         </v-btn>
      </v-flex> -->
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap class="tablefont" px-4 pt-4>
            <v-flex xs12 sm3 md1 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12 >
              <span>Bill No</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
              color="#b234a9"
                placeholder="Bill.No"
                v-model="Details.bill"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span>Date</span>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="#b234a9"
                    v-model="billDate"
                    placeholder="Bill Date"
                    outlined
                    dense hide-details
                    v-bind="attrs"
                    v-on="on"
                    disabled
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="billDate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
                <!-- @input="(menu2 = false)(getBording())" -->
              </v-menu>
                </v-flex>
              </v-layout>
             
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span>Supplier name</span>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
              color="#b234a9"
                :items="supplierList"
                v-model="sName"
                placeholder="Select Supplier"
                item-text="supplierName"
                item-value="_id"
                outlined
                :disabled="savedList.length > 0?true:false"
                hide-details
                dense
              ></v-autocomplete>
                </v-flex>
              </v-layout>
             
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span>Invoice No.</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
              color="#b234a9"
                placeholder="Invoice.No"
                v-model="invoicenumber"
                outlined
                dense
                hide-details
              ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span>Invoice Date</span>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="#b234a9"
                    v-model="invoiceDate"
                    placeholder="To Date"
                    outlined hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceDate"
                  no-title
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
                </v-flex>
              </v-layout>
             
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span> Thankam Rate</span>
                </v-flex>
                <v-flex xs10>
                  <v-text-field
              color="#b234a9"
                placeholder="Thankam Rate"
                v-model="goldRate"
                outlined
                :disabled="isLock == true ? true : false"
                dense
                hide-details
              ></v-text-field>
                </v-flex>
                <v-flex xs2>
              <v-layout wrap justify-center fill-height>
                <v-flex xs12 align-self-center text-center>
                  <v-icon color="#3F053C" v-if="isLock == true" 
                    >mdi-lock</v-icon
                  >
                  <v-icon color="#3F053C" v-else @click="setLock()"
                    >mdi-lock-open-outline</v-icon
                  >
                </v-flex>
             
              </v-layout>
            </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md1 pr-2 text-left align-self-baseline pt-5>
              <v-layout wrap justify-end py-1>
                <v-flex xs12 class="buttons1">
                  <v-btn class="button1" dark color="#3F053C" @click="checkItem()">
                    <v-icon>mdi-plus</v-icon> Item
                  </v-btn>
                </v-flex>
              </v-layout>
             
            </v-flex>
          </v-layout>
      
          <v-layout px-4 py-4>
            <v-flex xs12 v-if="savedList">
              <v-card elevation="0" class="pa-1" v-if="savedList.length > 0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <!-- <th class="text-left tablefont"><b>CATEGORY</b></th> -->
                            <th class="text-left tablefont"><b>ITEM</b></th>
                            <!-- <th class="text-left tablefont"><b>DESIGN</b></th>
                            <th class="text-left tablefont"><b>CODE</b></th> -->
                            <th class="text-left tablefont"><b>COUNT</b></th>
                            <th class="text-left tablefont"><b>ITEM/WT(gm)</b></th>
                            <th class="text-left tablefont"><b>NET.WT(gm)</b></th>
                            <!-- <th class="text-left tablefont"><b>Gross.Wt </b></th> -->
                            <th class="text-left tablefont"><b>COST(%)</b></th>
                            <!-- <th class="text-left tablefont"><b>MAKING CHARGE(₹)</b></th> -->
                            <th class="text-left tablefont"><b>STONE PRICE(₹)</b></th>
                            <th class="text-left tablefont"><b>STONE WT(gm)</b></th>
                            <!-- <th
                              class="text-left subhed"
                              v-if="GstStat == 'GST'"
                            >
                              <b>CGST</b>
                            </th>
                            <th
                              class="text-left subhed"
                              v-if="GstStat == 'GST'"
                            >
                              <b>SGST</b>
                            </th>
                            <th class="text-left subhed"><b>TOTAL(Rs.)</b></th> -->
                            <th class="text-left tablefont"><b>ACTION</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in savedList"
                            :key="i"
                            class="table"
                          >
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <!-- <td>
                              <span v-if="value.designId">{{
                                value.designId.categoryId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.itemId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.name
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.supplierDesignCode">{{
                                value.supplierDesignCode
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>

                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.makingCharge">{{ value.makingCharge }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td v-if="GstStat == 'GST'">
                              <span v-if="value.cgst">{{ value.cgst }}</span>
                              <span v-else>-</span>
                            </td>
                            <td v-if="GstStat == 'GST'">
                              <span v-if="value.sgst">{{ value.sgst }}</span>
                              <span v-else>-</span>
                            </td>
                            <td v-if="GstStat == 'NOGST'">
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount.toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td v-if="GstStat == 'GST'">
                              <span v-if="value.amountWithGst">{{
                                value.amountWithGst.toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td> -->

                            <td>
                              <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curItem = value),
                                    (tstcat = value.designId),
                                    (editdialogue = true),checktype2(curItem),
                                    getCategory()
                                "
                                >mdi-pencil</v-icon
                              >
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <!-- <v-layout wrap v-if="itemz.length > 0">
                  <v-flex mt-3>
                    <v-btn dark color="#3F053C" block @click="submit()"
                      >Save</v-btn
                    >
                  </v-flex>
                </v-layout> -->
              </v-card>
              <v-card elevation="0" class="pa-1" v-else>
                <v-layout wrap>
                  <v-flex xs12 text-center pa-4 class="tablefont1">
                    No data found
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-if="savedList">
         <v-spacer></v-spacer>
            <v-flex xs12 lg6 px-4 py-4 align-self-end v-if="savedList.length > 0">
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs12 sm9 pr-4 align-self-center class="subhed2" text-right>
                  Total Weight (gm) 
                </v-flex>
                <v-flex xs12 sm2 class="subhed3" text-center>
                  <v-card ma-4 outlined class="py-2">
                    <span v-if="totalnetweight">{{
                      totalnetweight.toFixed(2)
                    }}</span>
                    <span v-else>-</span>
                  </v-card>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 pr-4 align-self-center class="subhed2" text-right
                  >Making Charge :
                </v-flex>
                <v-flex xs2 class="subhed3" text-center>
                  <v-card ma-4 outlined class="py-2">
                    <span v-if="totalMakingCharge">{{
                      totalMakingCharge.toFixed(2)
                    }}</span>
                    <span v-else>-</span>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 align-self-center pr-4 class="subhed2" text-right
                  >Total Amount :
                </v-flex>
                <v-flex v-if="GstStat == 'GST'" xs2 class="subhed3" text-center>
                  <v-card ma-4 outlined class="py-2">
                    <span v-if="totalCostWithGst">{{
                      totalCostWithGst.toFixed(2)
                    }}</span>
                    <span v-else>-</span>
                  </v-card>
                </v-flex>
                <v-flex v-else xs3 class="subhed3" text-center
                  ><span v-if="finalTotal">{{ finalTotal.toFixed(2) }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout> -->
              <v-layout wrap justify-end>
                <v-flex xs12 sm4 md5 text-right pr-2 pb-2 pb-sm-0 class="buttons1">
                  <v-btn class="buttons1" color="#3F053C" dark @click="validate1()"
                    >Save & Settle Later
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm4 lg5 text-right v-if="bill" class="buttons1">
                  <v-btn
                    class="buttons1" color="#3F053C"
                    dark
                    @click="
                      validate2()"
                    >Save & Generate Bill
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Delete</span
                ></v-flex
              >
              <v-flex xs12 sm8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="buttons1"
                dark
                @click="deleteStaff()"
                >Delete</v-btn
              >
              <!-- <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="itemz.splice(removeitemIndex, 1), (dialoge = false)"
                >OK</v-btn
              > -->
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="900px" v-model="addnewItem" persistent>
          <v-form ref="myForm">
          <v-card width="1000px" class="pa-4">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Item</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addnewItem = false),(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center pt-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap pb-4>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Category</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                    color="#b234a9"
                      :items="categoryList"
                      v-model="purchaseItem.test"
                      placeholder="Select Category"
                      item-text="name"
                      item-value="_id"
                      @input="getItem()"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Item</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                    color="#b234a9"
                      :items="ItemList"
                      v-model="purchaseItem.test2"
                      placeholder="Select Item"
                      item-text="name"
                      item-value="_id"
                      @input="getDesign()"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Design</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                    color="#b234a9"
                      :items="designList"
                      v-model="purchaseItem.design"
                      placeholder="Select Design"
                      item-text="name"
                      item-value="_id" 
                      @change="checktype(purchaseItem.design)"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4 v-if="dtype == true">
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Count</span>
                  </v-flex>
                  <v-flex xs12>{{purchaseItem.countOrSplit}}
                    <v-text-field
                    color="#b234a9"
                    :disabled="dtype == true ? false : true"
                      @input="cal1()"
                      placeholder="Count"
                      v-model="purchaseItem.Count"
                      outlined type="number"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Item/weight(gm)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                    color="#b234a9"
                      @input="cal1()"
                      placeholder="Item/weight"
                      v-model="purchaseItem.wt"
                      outlined type="number"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- </v-layout>
            <v-layout wrap py-4 > -->
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Stone Price(₹)/item</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                    color="#b234a9"
                      placeholder="Stone Price"
                      v-model="purchaseItem.StPrice"
                      outlined
                      dense type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Stone Weight(gm)/item</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                    color="#b234a9"
                      placeholder="Stone Weight"
                      v-model="purchaseItem.StoneWt"
                      outlined
                      dense type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Net.Wt(gm)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                    color="#b234a9"
                      @input="cal2()"
                      placeholder="Net.Wt"
                      disabled type="number"
                      v-model="purchaseItem.netwt"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Seller Cost(%)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                    color="#b234a9"
                    :rules="[maxValueRule]"
                      @input="cal2()" type="number"
                      placeholder="Seller Cost"
                      v-model="purchaseItem.cost"
                      outlined
                      maxlength="3"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-spacer></v-spacer>
            
              <v-flex xs2 text-right align-self-end pt-2  pl-4 >
                <!-- :disabled="check == false" -->
                <v-btn
                  color="#3F053C"
                  class="buttons1 mr-4"
                  dark block 
                  @click="valid()"
                  >Save</v-btn
                ></v-flex
              >
            </v-layout>
          </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="800px" v-model="editdialogueOld">
          <!-- <v-form v-model="addcat"  ref="addcat"  @submit.prevent> -->
          <v-card width="800px" class="pa-4">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Edit Item : {{ curItem.supplierDesignCode }}</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 class="text-left" >
              <!-- <v-flex xs2 pr-2>
                <span class="subhed3">Code</span>
              </v-flex> -->
              <!-- <v-flex xs2 pr-2>
                <span class="subhed3">Item</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Design</span>
              </v-flex> -->
              <v-flex xs4 pr-2 v-if="dtype2==true">
                <span class="subhed3" >Count</span>
              </v-flex>
              <v-flex xs4 pr-2>
                <span class="subhed3">Item/weight(gm)</span>
              </v-flex>
              <v-flex xs4 pr-2>
                <span class="subhed3">Stone Price</span>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 pb-4>
              <!-- <v-flex text-center xs2 pr-2 v-if="curItem.designId">
                <v-text-field
                color="#b234a9"
                  v-if="curItem.designId.categoryId"
                  disabled
                  v-model="curItem.designId.categoryId.code"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex> -->
              <!-- <v-flex text-center xs2 pr-2 v-if="curItem.designId">
                <v-text-field
                color="#b234a9"
                  v-if="curItem.designId.categoryId"
                  disabled
                  v-model="curItem.designId.categoryId.code"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex> -->
              <!-- <v-flex xs2 pr-2 v-if="curItem.designId">
                <v-text-field
                color="#b234a9"
                  v-if="curItem.designId.itemId"
                  disabled
                  v-model="curItem.designId.itemId.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pr-2 v-if="curItem.designId">
                <v-text-field
                 color="#b234a9"
                  v-if="curItem.designId.name"
                  disabled
                  v-model="curItem.designId.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex> -->
              <v-flex xs4 pr-2 v-if="dtype2==true">
                <v-text-field 
                color="#b234a9"
                  @input="editcal1()"
                  placeholder="Count"
                  v-model="curItem.count"
                  outlined
                  :disabled="curItem.designId.countOrSplit=='Split'?true:false"
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs4 pr-2>
                <v-text-field
                color="#b234a9"
                  @change="editcal1()"
                  placeholder="Item/weight"
                  v-model="curItem.itemPerWeight"
                  outlined type="number"
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs4 pr-2>
                <v-text-field
                color="#b234a9"
                  placeholder="Stone Price"
                  v-model="curItem.stonePrice"
                  outlined
                  dense type="number"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 pb-4 class="text-left" justify-space-around>
           
              <v-flex xs4 pr-2>
                <span class="subhed3">Stone Weight</span>
              </v-flex>
              <v-flex xs4 pr-2>
                <span class="subhed3">Net.Wt</span>
              </v-flex>

              <v-flex xs4 pr-2>
                <span class="subhed3">Seller Cost</span>
              </v-flex>
              <!-- <v-flex xs2 pr-2>
                <span class="subhed3">Making Charges</span>
              </v-flex> -->
            </v-layout>
            <v-layout wrap px-4 justify-space-around>
             
              <v-flex xs4 pr-2>
                <v-text-field
                color="#b234a9"
                  placeholder="Stone Weight"
                  v-model="curItem.stoneWeight"
                  outlined
                  dense type="number"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs4 pr-2>
                <v-text-field
                color="#b234a9"
                  @input="editcal2()"
                  placeholder="Net.Wt"
                  disabled type="number"
                  v-model="curItem.netWeight"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs4 pr-2>
                <v-text-field
                color="#b234a9"
                  @input="editcal2()"
                  placeholder="Seller Cost"
                  v-model="curItem.cost"
                  outlined type="number"
                  maxlength="3"
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs2 pr-2>
                <v-text-field
                color="#b234a9"
                  placeholder="Making Charge"
                  v-model="curItem.makingCharge"
                  outlined
                  dense disabled
                  hide-details
                ></v-text-field>
              </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="(editdialogue = false),(dtype2 = false)"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="editCheck()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
          <!-- </v-form> -->
        </v-dialog>
        <v-dialog width="700px" v-model="editdialogue" persistent>
          <v-form ref="myForm">
          <v-card width="700px" class="pa-4">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Item : {{ curItem.supplierDesignCode }}</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(editdialogue = false),(dtype2 = false)"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center pt-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap pb-4>
              <v-flex xs12 sm4 px-4 v-if="dtype2==true">
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Count</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field 
                color="#b234a9"
                  @input="editcal1()"
                  placeholder="Count"
                  v-model="curItem.count"
                  outlined
                  :disabled="curItem.designId.countOrSplit=='Split'?true:false"
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm4 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Item/weight(gm)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                color="#b234a9"
                  @change="editcal1()"
                  placeholder="Item/weight"
                  v-model="curItem.itemPerWeight"
                  outlined type="number"
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm4 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Stone Price</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                color="#b234a9"
                  placeholder="Stone Price"
                  v-model="curItem.stonePrice"
                  outlined
                  dense type="number"
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm4 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Stone Weight(gm)/item</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                color="#b234a9"
                  placeholder="Stone Weight"
                  v-model="curItem.stoneWeight"
                  outlined
                  dense type="number"
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm4 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Net.Wt(gm)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                color="#b234a9"
                  @input="editcal2()"
                  placeholder="Net.Wt"
                  disabled type="number"
                  v-model="curItem.netWeight"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm4 px-4>
                <v-layout wrap>
                  <v-flex xs12 pb-1 pt-4 text-left>
                    <span class="tablefont0">Seller Cost(%)</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                color="#b234a9"
                  @input="editcal2()"
                  placeholder="Seller Cost"
                  v-model="curItem.cost"
                  outlined type="number"
                  maxlength="3"
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-spacer></v-spacer>
            
              <v-flex xs2 text-right align-self-end pt-1  px-4>
                <!-- :disabled="check == false" -->
                <v-btn
                  color="#3F053C"
                  class="buttons1 mr-4"
                  dark block 
                  @click="editCheck()"
                  >Edit</v-btn
                ></v-flex
              >
            </v-layout>
          </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
      v-model="queryPopUpDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '60vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '30vw'
      "
    >
      <v-card tile color="#FFFFFF">
        <v-layout wrap justify-center pa-2>
          <v-flex xs12 text-left pa-4>
            <span class="mainHeader2">BOARDING RATE</span>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> THANKAM Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    autofocus
                    placeholder="0"
                    class="my-text-field"
                    prefix="₹"
                    v-model="thankamRate"
                    type="number"
                    solo
                    flat
                    style="font-size: 30px"
                    dense
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> GOLD Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    prefix="₹"
                    v-model="gold"
                    type="number"
                    style="font-size: 30px"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    flat
                    dense
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> SILVER Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    v-model="silverRate"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    prefix="₹"
                    style="font-size: 30px"
                    flat
                    dense
                    height="60px"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> PLATINUM Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    style="font-size: 30px"
                    prefix="₹"
                    v-model="platinumRate"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    flat
                    height="60px"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> DIAMOND </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    prefix="₹"
                    style="font-size: 30px"
                    v-model="diamond"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    dense
                    flat
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs3 text-right pa-4 align-self-end>
            <v-btn
              height="45px"
              width="80px"
              color="#3F053C"
              class="body-2 font-weight-bold"
              dark
              block
              @click="EditRate()"
              >Save</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
       //bordingrate
       gold: "",
      thankamRate: "",
      silverRate: "",
      platinumRate: "",
      diamond: "",
      queryPopUpDialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      //
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      testId: null,
      timeout: 2000,
      msg: null,
      itemz: [],
      totalPurchaseCost: null,
      totalMakingCharge: null,
      username: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      addnewItem: false,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      bill: null,
      purchaseno: null,
      thankamcode: null,
      sName: null,
      thankamrate: null,
      invoiceDate1: null,
      makingcost: null,
      savedList: [],
      Details: [],
      designList: [],
      menu3: false,
      menu2: false,
      returndata: null,
      check: false,
      categoryList: [],
      ItemList: [],
      removeitemIndex: null,
      dialoge: false,
      HSN: null,
      supplierList: [],
      totalnetweight: null,
      invoicenumber: null,
      invoiceDate: null,
      finalTotal: null,
      // goldRate: localStorage.getItem("thankamRate"),
      tst1: null,
      tst2: null,
      tst3: null, 
      test4: null,
      isLock: false,
      CodeList: [],
      purchaseItem: {
        test: null,
        test2: null,
        design: null,
        Count:"",
        cost: 0,
        netwt: 0,
        totalamt: 0,
        wt: 0,
        TotalCost: 0,
        StPrice: 0,
        StoneWt: 0,
        MarkingC: 0,
        // discount: 0,
        // adtouchrate: 0,
        // admakingcharge: 0,
      },
      singleitem: {
        categoryId: null,
        itemId: null,
        designId: null,
        // designName: null,
        count: null,
        // grossWeight: null,
        itemPerWeight: null,
        cost: null,
        totalAmount: null,
        stonePrice: null,
        stoneWeight: null,
        makingCharge: null,
        // boardingRate: null,
        netWeight: null,
        // totalAmount: null,
        // hsnCodeId: null,
      },
      tstcat: {},
      curItem: [],
      editdialogue: false,
      curId: null,
      paymentTypes: [],
      checkvalue: false,
      purchaseId: null,
      editdialogueOld:false,
      payment: [
        {
          paymentType: null,
          payAmount: null,
        },
      ],
      thankamDetails: [
        {
          thankamId: "63d3a2d42d13b00bbea3a98f",
          boardingRate: null,
          thankamWeight: null,
          batchCode: null,
        },
      ],
      newdate: null,
      new: null,
      GstStat: "GST",
      dtype:false,
      dtype2:false,
      myGoldRate: localStorage.getItem("thankamRate") || 0
    };
  },
  // watch: {
  //  purchaseItem:{

  //     deep:true,
  //     handler: function (){
  //       if(this.purchaseItem.test){
  //     this.getItem();
  //       }
  //        if(this.purchaseItem.test2){
  //     this.getDesign();
  //       }
  //     }
  // }

  // },
  mounted() {
    // if (this.billDate) {
    //   this.getBording();
    // }
    this.userinfo();
    this.getBill();
    this.getSupplier();
    this.getHSN();
  },
  computed: {
    goldRate: {
    get() {
      return this.myGoldRate;
    },
    set(value) {
      this.myGoldRate = value;
    }
  }
  },
  watch:{
    goldRate(){
      console.log("MygoldRate=",this.MygoldRate)
      console.log("newrate=",this.goldRate)
    }
  },
  methods: {
    resetForm() {
      this.$refs.myForm.reset();
    },
    maxValueRule(value) {
      if (value > 100) {
        return "Maximum value is 100";
      }
      return true;
    },
    setLock() {
     
      if(this.goldRate>0){
        this.isLock = true;
      localStorage.setItem("isLock", this.isLock);
      console.log("lock", this.isLock);
        this.LockItem();
      }
      else{
        this.isLock = false;
        this.msg = "Invalid Thankam Rate";
            this.showsnackbar = true;
      }
    },
    setLockoff() {
      this.isLock = false;
      localStorage.setItem("isLock", this.isLock);
      console.log("lock", this.isLock);
    },
    LockItem() {
      axios({
        url: "/purchase/lock",
        method: "POST",
        data: {
          thankamRate: this.goldRate,
          purchaseId: this.purchaseno,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this. getData();
            // this.finalSubmit();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    
    },
    AutoLockItem() {
      axios({
        url: "/purchase/lock",
        method: "POST",
        data: {
          thankamRate: this.goldRate,
          purchaseId: this.purchaseno,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this. getData();
            this.finalSubmit();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    
    },
  
    checkItem() {
      if (!this.invoiceDate) {
        this.msg = "Please choose invoice date ";
        this.showsnackbar = true;
      } else if (!this.sName) {
        this.msg = "Please choose supplier ";
        this.showsnackbar = true;
      } else if (!this.goldRate) {
        this.msg = "Please enter thankam rate ";
        this.showsnackbar = true;
      } else if (this.goldRate && this.goldRate<=0) {
        this.msg = "Please enter valid thankam rate ";
        this.showsnackbar = true;
      }  else {
        console.log("inv", this.invoiceDate);
        this.getCategory();
        this.addnewItem = true;
      }
    },
    validate1() {
      if (!this.invoiceDate) {
        this.msg = "Please choose invoice date ";
        this.showsnackbar = true;
      } else if (!this.billDate) {
        this.msg = "Please choose bill date ";
        this.showsnackbar = true;
      } else if (!this.sName) {
        this.msg = "Please choose supplier ";
        this.showsnackbar = true;
      } else if (!this.invoicenumber) {
        this.msg = "Please choose invoice number ";
        this.showsnackbar = true;
      } else if (!this.invoiceDate) {
        this.msg = "Please choose invoice Date ";
        this.showsnackbar = true;
      } else if (!this.goldRate || this.goldRate==0) {
        this.msg = "Please choose thankam rate ";
        this.showsnackbar = true;
      } else if (this.itemz.length>0) {
        this.msg = "Please choose items ";
        this.showsnackbar = true;
      } else {
        this.submit();
        // console.log("suplier==",this.sName)
      }
    },
     validate2() {
      if (!this.invoiceDate) {
        this.msg = "Please choose invoice date ";
        this.showsnackbar = true;
      } else if (!this.billDate) {
        this.msg = "Please choose bill date ";
        this.showsnackbar = true;
      } else if (!this.sName) {
        this.msg = "Please choose supplier ";
        this.showsnackbar = true;
      } else if (!this.invoicenumber) {
        this.msg = "Please choose invoice number ";
        this.showsnackbar = true;
      } else if (!this.invoiceDate) {
        this.msg = "Please choose invoice Date ";
        this.showsnackbar = true;
      } else if (this.itemz.length>0) {
        this.msg = "Please choose items ";
        this.showsnackbar = true;
      } else if (!this.goldRate || this.goldRate==0) {
        this.msg = "Please choose thankam rate ";
        this.showsnackbar = true;
      }
      //  else if (this.isLock==false) {
      //   this.msg = "Please Lock Thankam Rate ";
      //   this.showsnackbar = true;
      // }
      else {
        if(this.isLock==false){
          this.AutoLockItem();
        }
        else{
          this.finalSubmit();
        }
        // this.$router.push('/PurchaseSettlement2?id=' + this.testId)
        // console.log("suplier2==",this.sName)
      }
    },
    changeGST() {
      this.GstStat = "GST";
      console.log("gststst=", this.GstStat);
    },
    changeGST2() {
      this.GstStat = "NOGST";
      console.log("gststst=", this.GstStat);
    },
    cal1() {
      if(this.purchaseItem.Count>0){
        console.log("count>0")
      this.purchaseItem.netwt = this.purchaseItem.Count * this.purchaseItem.wt;
      }
      else{
        console.log("count-else")

      this.purchaseItem.netwt = this.purchaseItem.wt;
      }
      console.log("purchaseItem.netwt=", this.purchaseItem.netwt);
    },
    cal2() {
      //
      this.tst1 = this.purchaseItem.netwt * this.goldRate;
      this.tst2 = this.purchaseItem.cost * 0.01;
      this.purchaseItem.MarkingC = this.tst1 * this.tst2;
      console.log("tst1", this.tst1);
      console.log("tst2", this.tst2);
      this.tst3 = this.tst1 + this.purchaseItem.MarkingC;
      console.log("purchaseItem.MarkingC=", this.purchaseItem.MarkingC);
    },
    editcal1() {
      // this.curItem.netWeight=0;
      console.log("curItem.count=",this.curItem.count);
      console.log("curItem.itemPerWeight=",this.curItem.itemPerWeight);

      if(this.curItem.count){
      this.curItem.netWeight = this.curItem.count * this.curItem.itemPerWeight;
      console.log("if-curItem.netWeight=",this.curItem.netWeight);

      }
      else{
      this.curItem.netWeight = this.curItem.itemPerWeight*1;
      console.log("else-curItem.netWeight=",this.curItem.netWeight);

      }
      this.editcal2();
    },
    editcal2() {
      //
      this.tst1 = this.curItem.netWeight * this.goldRate;
      this.tst2 = this.curItem.cost * 0.01;
      this.curItem.makingCharge = this.tst1 * this.tst2;
      console.log("tst1", this.tst1);
      console.log("tst2", this.tst2);
      console.log("curItem.makingCharge", this.curItem.makingCharge);
      this.tst3 = this.tst1 + this.curItem.makingCharge;
      console.log("tst3=", this.tst3);
    },
checktype(ID){
  console.log("ID=",ID)
  var singleobj =this.designList.find((x)=> x._id == ID);
  console.log("singleobj.countOrSplit==",singleobj.countOrSplit)
  if(singleobj.countOrSplit=="Count"){
    this.dtype=true;
    console.log("dtype=",this.dtype)
  }
  else{
    this.dtype=false;
    console.log("dtype=",this.dtype)
  }
},
checktype2(item){
  if(item.designId){
    if(item.designId.countOrSplit=='Split'){
      this.dtype2 = false;
    }
    else{
      this.dtype2 = true;
    }
  }
},
    choosetype() {
      console.log(this.paymentTypes, "array");
      if (this.paymentTypes.includes("Thankam")) {
        this.checkvalue = true;
      } else {
        this.checkvalue = false;
      }
    },
    editCheck(){
      if(this.dtype2==true){
        if (this.curItem.count<=0) {
        console.log("1")
        this.msg = "Please enter valid quantity ";
        this.showsnackbar = true;
      } 
      else{
        console.log("2")
        this.editItem();
      }
      }
      else{
        if (this.curItem.itemPerWeight<=0) {
        console.log("1")
        this.msg = "Please enter valid quantity ";
        this.showsnackbar = true;
      } 
      else{
        console.log("2")
        this.editItem();
      }
      }
    },
    editItem() {
      axios({
        url: "/purchaseitem/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          //     categoryId : this.curItem.designId.categoryId,
          // itemId : this.curItem.value.designId.itemId,
          // designId : this.curItem.designId.name,
          // designName : this.returndata.designName,
          thankamRate:this.goldRate,
          count: this.curItem.count,
          itemPerWeight: this.curItem.itemPerWeight,
          cost: this.curItem.cost,
          // totalAmount: this.tst3,
          netWeight: this.curItem.netWeight,
          // makingCharge: this.curItem.makingCharge,
          stonePrice: this.curItem.stonePrice,
          stoneWeight: this.curItem.stoneWeight,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteStaff() { 
      axios({
        url: "/purchaseitem/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //  validateInput() {
    //   if (!this.email) {
    //     this.msg = "Please provide email";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.name) {
    //     this.msg = "Please provide name";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.phone) {
    //     this.msg = "Please provide phone";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.username) {
    //     this.msg = "Please provide username";
    //     this.showsnackbar = true;
    //     return;
    //   }  else if (!this.address) {
    //     this.msg = "Please provide address";
    //     this.showsnackbar = true;
    //     return;
    //   }  else {
    //     this.addAdmin();
    //   }
    // },

    getBill() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/bill/generate",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   page: this.page,
        //   count: 20,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Details = response.data;
            this.bill = response.data.bill;
            this.invoicenumber = response.data.invoice;
            console.log("billno=", this.bill);
            this.getData();
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    valid(){
      if (!this.purchaseItem.test) {
        this.msg = "Please choose category ";
        this.showsnackbar = true;
      } else if (!this.purchaseItem.test2) {
        this.msg = "Please choose item ";
        this.showsnackbar = true;
      } 
      else if (!this.purchaseItem.design) {
        this.msg = "Please choose design ";
        this.showsnackbar = true;
      }
       else if (!this.purchaseItem.Count && this.dtype==true) {
        this.msg = "Please enter Count ";
        this.showsnackbar = true;
      } else if (!this.purchaseItem.wt) {
        this.msg = "Please enter item/weight ";
        this.showsnackbar = true;
      }  else if (this.purchaseItem.StPrice && !this.purchaseItem.StoneWt) {
        // if (!this.purchaseItem.StoneWt) {
        this.msg = "Please enter stone weight ";
        this.showsnackbar = true;
        // }
      }  else if (this.purchaseItem.StoneWt && !this.purchaseItem.StPrice) {
        // if (!this.purchaseItem.StPrice) {
        this.msg = "Please enter stone price ";
        this.showsnackbar = true;
        // }
      }else if (!this.purchaseItem.cost) {
        this.msg = "Please enter cost ";
        this.showsnackbar = true;
      } else if (this.purchaseItem.cost>100) {
        this.msg = "Please enter valid seller cost ";
        this.showsnackbar = true;
      } else if (!this.purchaseItem.netwt) {
        this.msg = "Please enter net weight ";
        this.showsnackbar = true;
      } else {
        
        this.addValues();
      }
    },
    addValues() {
      this.singleitem.categoryId = this.purchaseItem.test;
      this.singleitem.itemId = this.purchaseItem.test2;
      this.singleitem.designId = this.purchaseItem.design;
      // this.singleitem.designName = this.returndata.designName;
      this.singleitem.count = this.purchaseItem.Count;
      this.singleitem.itemPerWeight = this.purchaseItem.wt;
      this.singleitem.cost = this.purchaseItem.cost;
      // this.singleitem.totalAmount = this.tst3;
      this.singleitem.netWeight = this.purchaseItem.netwt;
      // this.singleitem.makingCharge = this.purchaseItem.MarkingC;
      this.singleitem.stonePrice = this.purchaseItem.StPrice;
      this.singleitem.stoneWeight = this.purchaseItem.StoneWt;
      // this.singleitem.boardingRate = this.purchaseItem.Br;
      // this.singleitem.netWeight = this.returndata.netWeight;
      // this.singleitem.totalAmount = this.purchaseItem.totalamt;
      // this.singleitem.hsnCodeId = this.HSN;
      this.addVariant();
    },
    // addVariant() {
    //   this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
    //   console.log("itemz=", this.itemz);
    //   (this.purchaseItem.test = null),
    //     (this.purchaseItem.test2 = null),
    //     (this.purchaseItem.design = null);
    //   this.purchaseItem.Count = null;
    //   this.purchaseItem.netwt = null;
    //   this.purchaseItem.wt = null;
    //   this.purchaseItem.MarkingC = null;
    //   this.purchaseItem.StPrice = null;
    //   this.purchaseItem.StoneWt = null;
    //   this.purchaseItem.totalamt = null;
    //   this.check = false;
    //   this.returndata = [];
    // },
    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      console.log("itemz=", this.itemz);
      (this.purchaseItem.test = null),
        (this.purchaseItem.test2 = null),
        (this.purchaseItem.design = null);
      this.purchaseItem.Count = 0;
      this.purchaseItem.netwt = 0;
      this.purchaseItem.wt = 0;
      this.purchaseItem.cost = 0;
      // this.tst3 = 0;
      // this.purchaseItem.MarkingC = 0;
      this.purchaseItem.StPrice = 0;
      this.purchaseItem.StoneWt = 0;
      // this.purchaseItem.totalamt = null;
      this.check = false;
      this.returndata = [];
      axios({
        url: "/pendingitem/add",
        method: "POST",
        data: {
          thankamRate: this.goldRate,
          isLock: this.isLock,
          items: this.itemz,
          billNo: this.Details.bill,
          date: this.billDate,
          supplierId: this.sName,
          // invoiceNo: this.Details.invoice,
          invoiceNo: this.invoicenumber,
          invoiceDate: this.invoiceDate,
          // taxType: this.GstStat,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addnewItem = false;
            this.itemz = [];
            this.purchaseno = response.data.purchaseId;
            console.log("purnum=", this.purchaseno);
            this.getData();
          } else {
            this.itemz = [];
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          billNo: this.bill,
          purchaseId: this.purchaseno,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.savedList = response.data.data;
            this.totalCostWithGst = response.data.purchasedata.totalCostWithGst;
            this.sName = response.data.purchasedata.supplierId._id;
            console.log("sName=====",this.sName)
            this.taxType = response.data.purchasedata.taxType;
            if(response.data.purchasedata.isLock==true){
            this.isLock = true;
            }
            this.goldRate=response.data.purchasedata.thankamRate
            this.invoiceDate = response.data.purchasedata.invoiceDate;
            this.invoiceDate1 = response.data.purchasedata.invoiceDate;
            this.invoiceDate1 = this.formatDate(this.invoiceDate1);
            console.log("invoiceDate1=", this.invoiceDate1);
            this.invoiceDate = this.invoiceDate1;
            this.testId = response.data.purchasedata._id;
            console.log("purchaseid=", this.testId);
            this.totalMakingCharge =
              response.data.purchasedata.totalMakingCharge;
            this.totalnetweight = response.data.purchasedata.totalNetWeight;
            this.finalTotal = response.data.purchasedata.totalPurchaseCost;
            this.purchaseno = response.data.purchasedata._id;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            // this.snackbar = true;
            // this.msg = response.data.msg;
            this.savedList=[];
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getCategory() {
      // this.appLoading = true;
      this.purchaseItem.test2="";
      this.purchaseItem.design="";
      axios({
        method: "get",
        url: "/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   page: this.page,
        //   count: 20,
        // },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
            this.purchaseItem.test2="";
      this.purchaseItem.design="";
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getItem() {
      // this.appLoading = true;
      this.purchaseItem.design="";
      axios({
        method: "get",
        url: "/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          categoryId: this.purchaseItem.test,
          // page: this.page,
          // count: 20,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.ItemList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getDesign() {
      // this.appLoading = true;
      axios({
        method: "get",
        url: "/design/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          itemId: this.purchaseItem.test2,
          // page: this.page,
          // count: 20,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.designList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getBording() {
    console.log("7");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          date: this.billDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.goldRate = response.data.data.thankamRate;
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    getHSN() {
      this.appLoading = true;
      axios({
        url: "/hsnCodes/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 50,
          searchKeyword: "",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.CodeList = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / 50);
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   page: this.page,
        //   count: 20,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.supplierList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddItem() {
      axios({
        url: "/individual/itemcalculation",
        method: "POST",
        data: {
          // taxType: this.GstStat,
          designId: this.purchaseItem.design,
          count: this.purchaseItem.Count,
          netWeight: this.purchaseItem.netwt,
          itemPerWeight: this.purchaseItem.wt,
          cost: this.purchaseItem.MarkingC,
          stonePrice: this.purchaseItem.StPrice,
          stoneWeight: this.purchaseItem.StoneWt,
          boardingRate: this.purchaseItem.Br,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;

            this.check = true;
            console.log("check=", this.check);
            this.returndata = response.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submit() {
      axios({
        method: "post",
        url: "/purchase/settle/later",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          thankamRate: this.goldRate,
          billNo: this.Details.bill,
          purchaseDate: this.billDate,
          supplierId: this.sName,
          invoiceNo: this.invoicenumber,
          // items: this.itemz,
          purchaseId: this.testId,
          invoiceDate: this.invoiceDate,
          isLock: this.isLock,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.uploadId = response.data.bill._id;
            // if (this.bills.length > 0) this.uploadBills(this.uploadId);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // location.reload();
            this.$router.push('/PurchaseList');
            // this.totalMakingCharge = response.data.totalMakingCharge;
            // this.totalPurchaseCost = response.data.totalPurchaseCost;
            // this.purchaseId = response.data._id;
            // this.getBill();
            // this.getData();
            // this.Details.bill = null;
            // this.billNo = null;
            // this.billDate = null;
            // this.sName = null;
            // this.Details.invoice = null;
            // this.invoiceDate = null;
            // this.itemz = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    finalSubmit() {
      axios({
        method: "post",
        // url: "/purchase/payment",
        url: "/purchase/generate/bill",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          billNo: this.Details.bill,
          purchaseDate: this.billDate,
          supplierId: this.sName,
          invoiceNo: this.invoicenumber,
          invoiceDate: this.invoiceDate,

          purchaseId: this.purchaseno,
          isLock: this.isLock,
          thankamRate: this.goldRate,
          // paymentType: this.paymentTypes,
          // payAmount: this.finalTotal,
          // thankamDetails: this.thankamDetails,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.uploadId = response.data.bill._id;
            // if (this.bills.length > 0) this.uploadBills(this.uploadId);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.$router.push("/PurchaseList");
            // this.$router.go(-1);
            // this.totalMakingCharge = response.data.totalMakingCharge;
            // this.totalPurchaseCost = response.data.totalPurchaseCost;
            // this.purchaseId = response.data.id;
            // this.Details.bill = null;
            // this.billNo = null;
            // this.billDate = null;
            // this.sName = null;
            // this.Details.invoice = null;
            // this.invoiceDate = null;
            // this.itemz = [];
            setTimeout(() => {
              this.$router.push('/PurchaseSettlement2?id=' + this.testId)
}, "1000");
        // this.$router.push('/PurchaseSettlement2?id=' + this.testId)

          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    userinfo() {
      axios({
      method: "GET",
      url: "/user/info",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params:{
        id:localStorage.getItem("id")
      }
    })
      .then((response) => {
        // console.log("menu-loginpage");
        if (response.data.status) {
        var check = response.data.goldrateadded;
            if (check == false) {
              console.log("check===-=", check);
              this.queryPopUpDialog = true;
            }
        }
        else{
          console.log("estpage user info")
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
    },
    EditRate() {
      axios({
        url: "/rate/add",
        method: "POST",
        data: {
          goldRate: this.gold,
          silverRate: this.silverRate,
          platinumRate: this.platinumRate,
          diamond: this.diamond,
          date: this.date,
          thankamRate: this.thankamRate,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.queryPopUpDialog = false;
            
            localStorage.setItem("diamond", response.data.data.diamond);
          localStorage.setItem("goldRate", response.data.data.goldRate);
          localStorage.setItem("platinumRate", response.data.data.platinumRate);
          localStorage.setItem("silverRate", response.data.data.silverRate);
          localStorage.setItem("thankamRate", response.data.data.thankamRate);
            location.reload();
            // this.goldrate = null;
            // this.silverrate = null;
            // this.platinumrate = null;
            // this.diamondrate = null;
            this.date = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
